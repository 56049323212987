// Temporary work around for Excel Advisors
// Hopefully these are temporary as well
const AE_CARRIER_SBGC = 'SBGC';
const AE_CARRIER_GLCO = 'GLCO';

const AE_BROKER_DEALER_NAME_PROD = 'ADVISORS EXCEL LLC';
const AE_BROKER_DEALER_NAME_QA = 'ADVISORS EXCEL, LLC';

const AE_FGA_ROLE = 'role:advisors_excel_imo_support';

export { AE_CARRIER_SBGC, AE_BROKER_DEALER_NAME_PROD, AE_BROKER_DEALER_NAME_QA, AE_FGA_ROLE, AE_CARRIER_GLCO };
